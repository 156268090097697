import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
    DroppableSections,
    ExceptionsRequirementsPanelTypes,
    SnackbarSeverity
} from 'core/constants/common';
import { AppThunk } from 'core/store/store';
import api from 'core/api';
import { setSnackbarState } from 'core/features/snackbar/snackbarSlice';
import { removeOrderTaxDocumentCodeThunk } from 'core/features/examOrderTaxes/examOrderTaxesSlice';
import { removeDocumentCode } from 'core/features/examOrderKeyDocumentGroup/examOrderKeyDocumentGroupSlice';
import { fetchExamOrderParentSuccessorsDocumentGroupData } from 'core/features/examOrderKeyDocumentGroup/examOrderKeyDocumentGroupSlice';
import { sortParagraphsBySequence } from 'core/helpers/sortParagraphsBySequence';
import { sortCodeTemplatesByAlphabet } from 'core/helpers/sortCodeTemplatesByAlphabet';
import {
    Paragraph,
    ParagraphSequence,
    Section,
    ParagraphError
} from 'types/exceptionsRequirements';
import { ParagraphCreateDto, ParagraphWriteDto } from 'types/dto/exceptionsRequirementsDto';
import { CodeTemplate } from 'types/codebook';
import { getExamOrderSearchPackageGroupDataThunk } from 'core/features/examOrderSearchPackageGroup/examOrderSearchPackageGroupSlice';

interface AddPhraseBelowPayload {
    codeId: string;
    panelName: ExceptionsRequirementsPanelTypes;
}

interface AddPhraseVisibilityPayload {
    isVisible: boolean;
    panelName: ExceptionsRequirementsPanelTypes;
}

interface AddSubPhraseVisibilityPayload {
    paragraphId: string;
    subParagraphId: string;
    isAddSubPhraseBelowEditorVisible: boolean;
}

interface SetNumberingPayload {
    isNumbering: boolean;
    panelName: ExceptionsRequirementsPanelTypes;
}

type SectionData = Partial<{
    [key in ExceptionsRequirementsPanelTypes]: {
        addPhraseBelowIndex: number;
        isAddPhraseBelowEditorVisible: boolean;
        isNumbering: boolean;
    };
}>;

interface ExceptionsState {
    sections: SectionData;
    paragraphsList: Paragraph[];
    targetDropPanel: DroppableSections;
    paragraphErrors: ParagraphError[];
    selectedCode: string;
}

const initialState: ExceptionsState = {
    sections: {
        [ExceptionsRequirementsPanelTypes.Exceptions]: {
            addPhraseBelowIndex: 0,
            isAddPhraseBelowEditorVisible: false,
            isNumbering: false
        },
        [ExceptionsRequirementsPanelTypes.Requirements]: {
            addPhraseBelowIndex: 0,
            isAddPhraseBelowEditorVisible: false,
            isNumbering: false
        }
    },
    paragraphsList: [],
    targetDropPanel: null,
    paragraphErrors: [],
    selectedCode: ''
};

const exceptionsSlice = createSlice({
    name: 'exceptionsRequirementsPanel',
    initialState,
    reducers: {
        /**
         * Set list of paragraphs to store
         * @param state Slice state
         * @param action Payload with the list of paragraphs to set
         */
        setParagraphList(state, action: PayloadAction<Paragraph[]>) {
            action.payload.sort(sortParagraphsBySequence);
            state.paragraphsList = action.payload;
        },
        /**
         * Remove a paragraph
         * @param state Slice state
         * @param action Payload with ID of the paragraph to remove
         */
        removeParagraph(state, action: PayloadAction<string>) {
            const targetParagraphIndex = state.paragraphsList.findIndex(
                (paragraph) => paragraph.id === action.payload
            );
            if (targetParagraphIndex !== -1) {
                state.paragraphsList.splice(targetParagraphIndex, 1);
            }
        },
        /**
         * Update a paragraph
         * @param state Slice state
         * @param action Payload with paragraph ID and the updated paragraph object
         */
        updateParagraph(state, action: PayloadAction<{ id: string; paragraph: Paragraph }>) {
            const { paragraph, id } = action.payload;
            let targetParagraphIndex = state.paragraphsList.findIndex(
                (paragraph) => paragraph.id === id
            );
            let targetSubParagraphIndex;
            const stateParagraph = state.paragraphsList.find(
                (paragraph) => paragraph.id === id
            );
            if (targetParagraphIndex == -1) {
                let subParaData;
                for (let i = 0; i < state.paragraphsList.length; i++) {
                    subParaData = state.paragraphsList[i].subParagraphs.find(
                        (item, subParaIndex) => {
                            if (item['id'] === id) {
                                targetParagraphIndex = i;
                                targetSubParagraphIndex = subParaIndex;
                            }
                            return item['id'] === id;
                        }
                    );
                    if (targetParagraphIndex != -1) {
                        break;
                    }
                }

                if (subParaData) {
                    state.paragraphsList[targetParagraphIndex].subParagraphs.splice(
                        targetSubParagraphIndex,
                        1,
                        { ...paragraph }
                    );
                }
            } else {
                state.paragraphsList[targetParagraphIndex] = {
                    ...paragraph,
                    isExpanded: stateParagraph?.isExpanded ?? false
                };
            }
        },
        /**
         * Update a paragraph's expanded state
         * @param state Slice state
         * @param action Payload with code template ID, isParagraphExpanded and documentId
         */
        updateParagraphExpandedState(
            state,
            action: PayloadAction<{
                paragraphId?: string;
                codeTemplateId?: string;
                isParagraphExpanded: boolean;
                documentId?: string;
            }>
        ) {
            const { paragraphId, codeTemplateId, isParagraphExpanded, documentId } =
                action.payload;
            let targetParagraphIndex = -1;
            if (codeTemplateId && documentId) {
                targetParagraphIndex = state.paragraphsList.findIndex(
                    (paragraph) =>
                        paragraph.codeTemplateId === codeTemplateId &&
                        paragraph.linkedDoc === documentId
                );
            } else if (paragraphId) {
                targetParagraphIndex = state.paragraphsList.findIndex(
                    (paragraph) => paragraph.id === paragraphId
                );
            }
            if (targetParagraphIndex > -1) {
                state.paragraphsList[targetParagraphIndex] = {
                    ...state.paragraphsList[targetParagraphIndex],
                    isExpanded: isParagraphExpanded
                };
            }
        },
        /**
         * Update a paragraph's expanded state
         * @param state Slice state
         * @param action Payload with code name
         */
        setSelectedCode(state, action: PayloadAction<{ codeName: string }>) {
            state.selectedCode = action.payload.codeName;
        },
        /**
         * Update sequence of paragraphs
         * @param state Slice state
         * @param action Payload with the updated paragraph sequence
         */
        updateParagraphListSequence(state, action: PayloadAction<ParagraphSequence>) {
            const sequence = action.payload;
            state.paragraphsList.forEach((paragraph) => {
                paragraph.sequence = sequence[paragraph.id];
                if (paragraph.subParagraphs?.length > 0) {
                    paragraph.subParagraphs.forEach((subParagraph) => {
                        subParagraph.sequence = sequence[subParagraph.id];
                    });
                }
            });
            state.paragraphsList.sort(sortParagraphsBySequence);
            state.paragraphsList.forEach((paragraph) => {
                if (paragraph.subParagraphs?.length > 0) {
                    paragraph.subParagraphs.sort(sortParagraphsBySequence);
                }
            });
        },
        /**
         * Set the index where a new phrase is added when user clicks 'add phrase below'
         * @param state Slice state
         * @param action Payload with the list of paragraphs to set
         */
        setAddPhraseBelowIndex(state, action: PayloadAction<AddPhraseBelowPayload>) {
            const { panelName, codeId } = action.payload;
            const requirements = state.paragraphsList.filter(
                (paragraph) =>
                    paragraph.section === ExceptionsRequirementsPanelTypes.Requirements
            );
            const exceptions = state.paragraphsList.filter(
                (paragraph) =>
                    paragraph.section === ExceptionsRequirementsPanelTypes.Exceptions
            );
            switch (panelName) {
                case ExceptionsRequirementsPanelTypes.Requirements:
                    state.sections[panelName].addPhraseBelowIndex = requirements.find(
                        (el) => el.id === codeId
                    )?.sequence;
                    break;
                case ExceptionsRequirementsPanelTypes.Exceptions:
                    state.sections[panelName].addPhraseBelowIndex = exceptions.find(
                        (el) => el.id === codeId
                    )?.sequence;
                    break;
            }
        },
        /**
         * Set the visibility of the 'add phrase below' legend in the exceptions or requirements panel
         * @param state Slice state
         * @param action Payload with the panel name and isVisible flag value
         */
        setAddPhraseBelowVisibility(state, action: PayloadAction<AddPhraseVisibilityPayload>) {
            const { panelName, isVisible } = action.payload;
            state.sections[panelName].isAddPhraseBelowEditorVisible = isVisible;
        },
        /**
         * Set the visibility of the numbering inputs in the exceptions or requirements panel
         * @param state Slice state
         * @param action Payload with the panel name and isNumbering flag value
         */
        setNumbering(state, action: PayloadAction<SetNumberingPayload>) {
            const { panelName, isNumbering } = action.payload;
            state.sections[panelName].isNumbering = isNumbering;
        },
        /**
         * Expand all paragraphs in the exceptions or requirements panel
         * @param state Slice state
         * @param action Payload with the panel name and isAllExpanded flag value
         */
        setAllParagraphExpanded(
            state,
            action: PayloadAction<{
                section: ExceptionsRequirementsPanelTypes;
                isAllExpanded: boolean;
            }>
        ) {
            const { section, isAllExpanded } = action.payload;
            state.paragraphsList.forEach((paragraph) => {
                if (paragraph.section === section) {
                    paragraph.isExpanded = isAllExpanded;
                }
            });
        },
        /**
         * Set the target panel where a code will be dropped
         * @param state Slice state
         * @param action Payload with the name of the droppable section to set as target
         */
        setTargetDropPanel(state, action: PayloadAction<DroppableSections>) {
            state.targetDropPanel = action.payload;
        },
        /**
         *
         * @param state Slice state
         * @param action Payload with the
         */
        setParagraphErrors(state, action: PayloadAction<ParagraphError[]>) {
            state.paragraphErrors = action.payload;
        },
        /**
         *
         * @param state
         * @param action
         */
        // addParagraphError(state, action: PayloadAction<ParagraphErrors>) {
        //     state.paragraphErrors.push(action.payload);
        // },
        // /**
        //  *
        //  * @param state
        //  * @param action
        //  */
        // removeParagraphError(state, action: PayloadAction<ParagraphErrors>) {
        //     state.paragraphErrors.forEach((element, index) => {
        //         if (action.payload.id == element.id) state.paragraphErrors.splice(index, 1);
        //     });
        // },
        /**
         * Set the visibility of Add Sub Phrase button below sub phrase
         * @param state Slice state
         * @param action Payload with paragraphId, subParagraphId and isAddSubPhraseBelowEditorVisible flag
         */
        setAddSubPhraseBelowVisibility(
            state,
            action: PayloadAction<AddSubPhraseVisibilityPayload>
        ) {
            const { paragraphId, subParagraphId, isAddSubPhraseBelowEditorVisible } =
                action.payload;
            const paragraphIndex = state.paragraphsList.findIndex(
                ({ id }) => id === paragraphId
            );
            const subParagraphIndex = state.paragraphsList[
                paragraphIndex
            ].subParagraphs.findIndex(({ id }) => id === subParagraphId);
            state.paragraphsList[paragraphIndex].subParagraphs[
                subParagraphIndex
            ].isAddSubPhraseBelowEditorVisible = isAddSubPhraseBelowEditorVisible;
        }
    }
});

export const {
    setAddPhraseBelowIndex,
    setAddPhraseBelowVisibility,
    setParagraphList,
    removeParagraph,
    updateParagraph,
    updateParagraphListSequence,
    setNumbering,
    setAllParagraphExpanded,
    setTargetDropPanel,
    setParagraphErrors,
    // addParagraphError,
    // removeParagraphError,
    updateParagraphExpandedState,
    setSelectedCode,
    setAddSubPhraseBelowVisibility
} = exceptionsSlice.actions;

/**
 * Fetch exceptions and requirements data for the order
 * @param {string} orderId ID of the order
 * @returns {AppThunk}
 */
export const getAllExceptionsAndRequirementsThunk =
    (orderId: string): AppThunk =>
    async (dispatch) => {
        try {
            const response = await api.exceptionsRequirements.getExceptions(orderId);
            dispatch(setParagraphList(response));
        } catch (err) {
            dispatch(
                setSnackbarState({
                    open: true,
                    message: `Get all paragraphs: ${err.message}`,
                    severity: SnackbarSeverity.Error
                })
            );
        }
    };

/**
 * Add a new paragraph (exception or requirement) to an order
 * @param {string} orderId ID of the order
 * @param {ParagraphCreateDto[]} paragraphData Paragraph object
 * @param {string} paragraphIdToExpand Paragraph ID to expand after add
 * @returns {AppThunk}
 */
export const addParagraphThunk =
    (
        orderId: string,
        paragraphData: ParagraphCreateDto[],
        paragraphIdToExpand?: string
    ): AppThunk =>
    async (dispatch) => {
        try {
            const response = await api.exceptionsRequirements.postExceptions(
                orderId,
                paragraphData
            );

            // Update response with expanded paragraphs if specified
            const updatedResponse = response.map((paragraph) => {
                if (paragraphIdToExpand && paragraph.id === paragraphIdToExpand) {
                    return { ...paragraph, isExpanded: true };
                }
                return paragraph;
            });

            // Update response with expanded paragraphs if not specified
            if (!paragraphIdToExpand) {
                paragraphData.forEach(({ codeTemplateId, sequence, section }) => {
                    const foundParagraph = updatedResponse.find(
                        (paragraph) =>
                            paragraph.codeTemplateId === codeTemplateId &&
                            paragraph.section === section &&
                            paragraph.sequence === sequence
                    );
                    if (foundParagraph) {
                        foundParagraph.isExpanded = true;
                        dispatch(setSelectedCode({ codeName: foundParagraph.code }));
                    }
                });
            }

            // Update paragraph list in store
            dispatch(setParagraphList(updatedResponse));
        } catch (err) {
            dispatch(
                setSnackbarState({
                    open: true,
                    message: `Add paragraphs: ${err.message}`,
                    severity: SnackbarSeverity.Error
                })
            );
        }
    };

/**
 * Remove a paragraph from an existing order
 * @param {string} orderId ID of the order
 * @param {string} paragraphId ID of the paragraph to remove
 * @param {string} paragraphIdToExpand ID of the paragraph to expand after removal
 * @returns {AppThunk}
 */
export const removeParagraphThunk =
    (orderId: string, paragraphId: string, paragraphIdToExpand?: string): AppThunk =>
    async (dispatch, getState) => {
        try {
            const paragraphList = getState().exceptionsData.paragraphsList;
            const targetParagraph = paragraphList.find((par) => par.id === paragraphId);
            await api.exceptionsRequirements.deleteParagraphById(orderId, paragraphId);
            if (targetParagraph?.linkedDoc !== '00000000-0000-0000-0000-000000000000')
                if (targetParagraph?.typeInfo?.isTaxDocument) {
                    dispatch(
                        removeOrderTaxDocumentCodeThunk(
                            orderId,
                            targetParagraph?.linkedDoc,
                            targetParagraph?.codeTemplateId
                        )
                    );
                } else
                    dispatch(
                        removeDocumentCode({ docId: targetParagraph?.linkedDoc, paragraphId })
                    );
            dispatch(removeParagraph(paragraphId));
            dispatch(updateSequenceThunk(paragraphIdToExpand));
        } catch (err) {
            dispatch(
                setSnackbarState({
                    open: true,
                    message: `Remove paragraph: ${err.message}`,
                    severity: SnackbarSeverity.Error
                })
            );
        }
    };

/**
 * Update a paragraph in an existing order
 * @param {string} orderId ID of the order
 * @param {string} paragraphId ID of the paragraph to update
 * @param {ParagraphWriteDto} paragraphData Updated paragraph object
 * @returns {AppThunk}
 */
export const updateParagraphThunk =
    (orderId: string, paragraphId: string, paragraphData: ParagraphWriteDto): AppThunk =>
    async (dispatch) => {
        try {
            const updatedParagraph: Paragraph =
                await api.exceptionsRequirements.updateParagraphById(
                    orderId,
                    paragraphId,
                    paragraphData
                );
            dispatch(
                updateParagraph({
                    id: paragraphId,
                    paragraph: { ...updatedParagraph, isExpanded: true }
                })
            );
            dispatch(fetchExamOrderParentSuccessorsDocumentGroupData(orderId));
            dispatch(getExamOrderSearchPackageGroupDataThunk(orderId));
        } catch (err) {
            dispatch(
                setSnackbarState({
                    open: true,
                    message: `Update paragraph: ${err.message}`,
                    severity: SnackbarSeverity.Error
                })
            );
        }
    };

/**
 * Change a paragraph on an order. This is the dedicated API for changing a code.
 * This was made to decrease complexity of the updateParagraphThunk method which used the
 * updateParagraphById api method.
 * @param {string} orderId
 * @param {string} paragraphId
 * @param {string} codeTemplateId
 * @param {boolean} keepLanguage
 * @returns {AppThunk}
 */
export const changeParagraphThunk =
    (
        orderId: string,
        paragraphId: string,
        codeTemplateId: string,
        keepLanguage: boolean
    ): AppThunk =>
    async (dispatch) => {
        try {
            const updatedParagraph: Paragraph =
                await api.exceptionsRequirements.changeParagraphById(
                    orderId,
                    paragraphId,
                    codeTemplateId,
                    keepLanguage
                );
            dispatch(
                updateParagraph({
                    id: paragraphId,
                    paragraph: { ...updatedParagraph, isExpanded: true }
                })
            );
            dispatch(fetchExamOrderParentSuccessorsDocumentGroupData(orderId));
            dispatch(getExamOrderSearchPackageGroupDataThunk(orderId));
        } catch (err) {
            dispatch(
                setSnackbarState({
                    open: true,
                    message: `Change Paragraph: ${err.message}`,
                    severity: SnackbarSeverity.Error
                })
            );
        }
    };

/**
 * link key document to an existing Requirement, Exception, or sub-paragraph
 * @param {string} orderId ID of the order
 * @param {string} paragraphId ID of the paragraph to update
 * @param {string} documentId ID of the document to link
 * @returns {AppThunk}
 */
export const linkDocumentToParagraphThunk =
    (orderId: string, paragraphId: string, documentId: string): AppThunk =>
    async (dispatch) => {
        try {
            const updatedParagraph: Paragraph = await api.exceptionsRequirements.linkDocument(
                orderId,
                paragraphId,
                documentId
            );
            dispatch(
                updateParagraph({
                    id: paragraphId,
                    paragraph: { ...updatedParagraph, isExpanded: true }
                })
            );
            dispatch(fetchExamOrderParentSuccessorsDocumentGroupData(orderId));
            dispatch(getExamOrderSearchPackageGroupDataThunk(orderId));
        } catch (err) {
            dispatch(
                setSnackbarState({
                    open: true,
                    message: `Link Key Document: ${err.message}`,
                    severity: SnackbarSeverity.Error
                })
            );
        }
    };

/**
 * Update the sequence in which paragraphs are displayed in an order
 * @param {string} orderId ID of the order
 * @param {ParagraphSequence} paragraphSequence Updated paragraph sequence
 * @param {string} paragraphIdToExpand ID of the paragraph to expand after update
 * @returns {AppThunk}
 */
export const updateSequenceListThunk =
    (
        orderId: string,
        paragraphSequence: ParagraphSequence,
        paragraphIdToExpand?: string
    ): AppThunk =>
    async (dispatch) => {
        try {
            dispatch(updateParagraphListSequence(paragraphSequence));
            const response = await api.exceptionsRequirements.updateParagraphSequence(
                orderId,
                paragraphSequence
            );
            const paragraphIndex = response.findIndex(
                (paragraph) => paragraph.id === paragraphIdToExpand
            );
            if (paragraphIndex !== -1) {
                const updatedParagraph = {
                    ...response[paragraphIndex],
                    isExpanded: true
                };
                response[paragraphIndex] = updatedParagraph;
            }
            dispatch(setParagraphList(response));
        } catch (err) {
            dispatch(
                setSnackbarState({
                    open: true,
                    message: err.message,
                    severity: SnackbarSeverity.Error
                })
            );
        }
    };

/**
 * Update the paragraph sequence by dragging and dropping a code to a new position
 * @param {number} sourceIndex Index of the code that was dragged
 * @param {number} destinationIndex Index of the position the code was dropped to
 * @param {Paragraph[]} arrayCopy Copy of the current list of paragraphs
 * @returns {AppThunk}
 */
export const reorderColumnThunk =
    (sourceIndex: number, destinationIndex: number, arrayCopy: Paragraph[]): AppThunk =>
    async (dispatch, getState) => {
        const paragraphsList = getState().exceptionsData.paragraphsList;
        const currentOrder = getState().currentExamOrderData.currentExamOrder.id;
        const movedItem = arrayCopy.find((item, index) => index === sourceIndex);
        arrayCopy.splice(sourceIndex, 1);
        arrayCopy.splice(destinationIndex, 0, movedItem);
        const newSequence: ParagraphSequence = paragraphsList.reduce(
            (acc, curr) => ({ ...acc, [curr.id]: curr.sequence }),
            {} as ParagraphSequence
        );
        arrayCopy.forEach((item, index) => {
            newSequence[item.id] = index + 1;
        });
        dispatch(updateSequenceListThunk(currentOrder, newSequence));
    };

/**
 * Update the sub paragraph sequence by dragging and dropping a code to a new position
 * @param {number} sourceIndex Index of the code that was dragged
 * @param {number} destinationIndex Index of the position the code was dropped to
 * @param {Paragraph[]} arrayCopy Copy of the current list of paragraphs
 * @param {string} parentParagraphID ID of the paragraph to expand after update
 * @returns {AppThunk}
 */
export const reorderSubParaColumnThunk =
    (
        sourceIndex: number,
        destinationIndex: number,
        arrayCopy: Paragraph[],
        parentParagraphID: string
    ): AppThunk =>
    async (dispatch, getState) => {
        const currentOrder = getState().currentExamOrderData.currentExamOrder.id;
        const [element] = arrayCopy.splice(sourceIndex, 1);
        arrayCopy.splice(destinationIndex, 0, element);
        const newSequence: ParagraphSequence = {};
        arrayCopy.forEach((item, index) => {
            newSequence[item.id] = index + 1;
        });
        dispatch(updateSequenceListThunk(currentOrder, newSequence, parentParagraphID));
    };

/**
 * Add a code to the paragraph list by dragging and dropping from the codebook
 * @param {number} sourceIndex Index of the dragged code in the codebook
 * @param {number} destinationCodeSequence Code sequence of newly added code
 * @param {ExceptionsRequirementsPanelTypes} targetColumn Name of the paragraph list the code was dropped to (exceptions or requirements)
 * @returns {AppThunk}
 */
export const addFromCodebookThunk =
    (
        sourceIndex: number,
        destinationCodeSequence: number,
        targetColumn: ExceptionsRequirementsPanelTypes
    ): AppThunk =>
    async (dispatch, getState) => {
        const { codeTemplates, lookupInput, targetSection } = getState().examCodeBookData;
        const currentOrder = getState().currentExamOrderData.currentExamOrder.id;

        // if we are in one section and tries to drop code of another section,
        // then simply returning back without any action
        if (targetColumn !== targetSection) return;

        const filterBySection = (template: CodeTemplate) => {
            if (targetSection === null) return true;
            return template.section === targetSection;
        };
        const handleFilterCodes = (template: CodeTemplate) => {
            if (!lookupInput) return true;
            else if (
                template.code?.toUpperCase().includes(lookupInput.toUpperCase()) ||
                template.body?.toUpperCase().includes(lookupInput.toUpperCase()) ||
                template.label?.toUpperCase().includes(lookupInput.toUpperCase())
            ) {
                return true;
            }
            return false;
        };
        const codeToAdd = codeTemplates
            .filter(filterBySection)
            .filter(handleFilterCodes)
            .sort(sortCodeTemplatesByAlphabet)
            .find((code, index) => index === sourceIndex);
        dispatch(
            addParagraphThunk(currentOrder, [
                {
                    codeTemplateId: codeToAdd.id,
                    sequence: destinationCodeSequence,
                    section:
                        targetColumn === ExceptionsRequirementsPanelTypes.Exceptions
                            ? Section.exception
                            : Section.requirement
                }
            ])
        );
    };

/**
 * Save the updated paragraph sequence to the BE
 * @param {string} paragraphIdToExpand ID of the paragraph to expand after update
 * @returns {AppThunk}
 */
export const updateSequenceThunk =
    (paragraphIdToExpand: string): AppThunk =>
    async (dispatch, getState) => {
        try {
            const { id: orderId } = getState().currentExamOrderData.currentExamOrder;
            const { paragraphsList } = getState().exceptionsData;
            const paragraphListCopy: Paragraph[] = JSON.parse(JSON.stringify(paragraphsList));
            const newSequence: ParagraphSequence = paragraphListCopy.reduce(
                (acc, curr) => ({ ...acc, [curr.id]: curr.sequence }),
                {} as ParagraphSequence
            );
            const exceptionsList = paragraphListCopy.filter((par) => par.section === 0);
            const requirementsList = paragraphListCopy.filter((par) => par.section === 1);
            exceptionsList.forEach((paragraph, index) => {
                newSequence[paragraph.id] = index;
            });
            requirementsList.forEach((paragraph, index) => {
                newSequence[paragraph.id] = index;
            });
            dispatch(updateSequenceListThunk(orderId, newSequence, paragraphIdToExpand));
        } catch (err) {
            dispatch(
                setSnackbarState({
                    open: true,
                    message: `Update paragraph sequence: ${err.message}`,
                    severity: SnackbarSeverity.Error
                })
            );
        }
    };

export default exceptionsSlice.reducer;
